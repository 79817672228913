<template>
  <div class="ad-banner">
    <div class="ad-track">
      <img
        v-for="(ads, index) in adss"
        :key="index"
        :src="ads.image"
        class="ad-logo"
      />
    </div>
  </div>

  <div style="width: 100%; height: 800px; position: relative">
    <p class="IriBaner"></p>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AdBanner",
  data() {
    return {
      adss: [], // Array for storing news data
    };
  },
  methods: {
    async fetchNews() {
      try {
        const response = await axios.get("/api"); // آدرس API خود را جایگزین کنید
        this.adss = response.data.ads; // اینجا داده‌های زیرمجموعه news را اختصاص می‌دهیم
      } catch (error) {
        console.error("خطا در دریافت داده‌های مهمان:", error);
      }
    },
  },
  mounted() {
    this.fetchNews(); // فراخوانی متد دریافت داده‌ها هنگام لود شدن
  },
};
</script>

<style scoped src="../assets/css/section.css"></style>
