<template>
  <header>
    <div
      id="myMenuCnt"
      ref="menu"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
    >
      <HeaderMoblie />
    </div>
    <div class="container">
      <MenuIcon @click="showMenu()" class="showMenu" />
      <div class="btns">
        <button>صفحه اصلی</button>
        <div @mouseleave="hideShowList(0)" class="flexdirection">
          <div class="flexbox">
            <button @mouseover="showList(0)" class="company">
              لیست <ArrowDown />
            </button>
            <ul ref="listVisible0" class="show">
              <li :class="{ showingList: listVisible0 }">دانش بنیان</li>
              <li :class="{ showingList: listVisible0 }">خلاق</li>
              <li :class="{ showingList: listVisible0 }">استارت اپ</li>
              <li :class="{ showingList: listVisible0 }">
                پارک های علم و فناوری
              </li>
              <li :class="{ showingList: listVisible0 }">شتاب دهنده ها</li>
              <li :class="{ showingList: listVisible0 }">فضای کار اشتراکی</li>
              <li :class="{ showingList: listVisible0 }">مراکز رشد</li>
              <li :class="{ showingList: listVisible0 }">مناطق آزاد ایران</li>
              <li :class="{ showingList: listVisible0 }">کار آفرین ها</li>
            </ul>
          </div>
        </div>
        <div @mouseleave="hideShowList(1)" class="flexdirection">
          <div class="flexbox">
            <button @mouseover="showList(1)" class="company">
              رویداد ها<ArrowDown />
            </button>

            <ul ref="listVisible1" class="show">
              <li @click="showEvent()" :class="{ showingList: listVisible1 }">
                استیج
              </li>
              <li @click="showEvent()" :class="{ showingList: listVisible1 }">
                سرمایه گذاری
              </li>
              <li @click="showEvent()" :class="{ showingList: listVisible1 }">
                داستان من
              </li>
              <li @click="showEvent()" :class="{ showingList: listVisible1 }">
                مصاحبه
              </li>
              <li @click="showEvent()" :class="{ showingList: listVisible1 }">
                پنل گفت و گو
              </li>
              <li @click="showEvent()" :class="{ showingList: listVisible1 }">
                کافه دورهمی
              </li>
            </ul>
          </div>
        </div>

        <div @mouseleave="hideShowList(2)" class="flexdirection">
          <div class="flexbox">
            <button @mouseover="showList(2)" class="company">
              خبرنامه<ArrowDown />
            </button>
            <ul ref="listVisible2" class="show">
              <li :class="{ showingList: listVisible2 }">خبر روز</li>
              <li :class="{ showingList: listVisible2 }">دستور عمل ها</li>
              <li :class="{ showingList: listVisible2 }">خط زمانی</li>
              <li :class="{ showingList: listVisible2 }">برنامه همایتی</li>
            </ul>
          </div>
        </div>

        <div @mouseleave="hideShowList(3)" class="flexdirection">
          <div class="flexbox">
            <button @mouseover="showList(3)" class="company">
              مجله نامه<ArrowDown />
            </button>
            <ul ref="listVisible3" class="show">
              <li :class="{ showingList: listVisible3 }">اینفوگرافی</li>
              <li :class="{ showingList: listVisible3 }">موشن گرافی</li>
              <li :class="{ showingList: listVisible3 }">ویدیو موشن</li>
              <li :class="{ showingList: listVisible3 }">گالری</li>
            </ul>
          </div>
        </div>

        <div @mouseleave="hideShowList(4)" class="flexdirection">
          <div class="flexbox">
            <button @mouseover="showList(4)" class="company">
              تماس با ما<ArrowDown />
            </button>
            <ul ref="listVisible4" class="show">
              <li @click="showContactUs()">ارتباط با ما</li>
              <li>همکاری داوطلبانه</li>
              <li>سخن ران ها</li>
              <li>حامیان مالی</li>
            </ul>
          </div>
        </div>
        <!-- <button>درباره ما</button> -->
      </div>

      <div class="logo">
        <span>IRI</span>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from "vue"; // Removed onMounted
import ArrowDown from "@/assets/svg , icon/ArrowDown.vue";
import MenuIcon from "@/assets/svg , icon/MenuIcon.vue";
import HeaderMoblie from "@/components/HeaderMoblie.vue";

export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showMenu() {
      this.show = !this.show;
      this.$refs.menu.style.right = "0";
    },
  },
  components: {
    MenuIcon,
    ArrowDown,
    HeaderMoblie,
  },
  setup() {
    const listVisibles = [
      ref(null),
      ref(null),
      ref(null),
      ref(null),
      ref(null),
    ];

    function showList(index) {
      if (listVisibles[index].value) {
        listVisibles[index].value.style.opacity = "1";
        listVisibles[index].value.style.zIndex = "0";
      }
    }

    function hideShowList(index) {
      if (listVisibles[index].value) {
        listVisibles[index].value.style.opacity = "0";
        listVisibles[index].value.style.zIndex = "-9";
      }
    }

    function showEvent() {
      const target = document.getElementById("eventsContent");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }

    function showContactUs() {
      const target = document.getElementById("showContactUs");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }

    return {
      listVisible0: listVisibles[0],
      listVisible1: listVisibles[1],
      listVisible2: listVisibles[2],
      listVisible3: listVisibles[3],
      listVisible4: listVisibles[4],
      showList,
      hideShowList,
      showEvent,
      showContactUs,
    };
  },
};
</script>

<style scoped src="@/assets/css/header.css"></style>
