<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 530.9 530.9"
    width="20"
    height="20"
  >
    <path
      d="M348 527.8L50.5 436.9c-37.6-11.5-58.8-51.3-47.3-89L94 50.5c11.5-37.6 51.3-58.8 89-47.3L480.5 94c37.6 11.5 58.8 51.3 47.3 89l-90.9 297.5c-11.5 37.6-51.3 58.8-88.9 47.3z"
    />
    <circle class="st0" cx="265.5" cy="265.5" r="226.8" />
    <circle class="st1" cx="265.5" cy="265.5" r="28.4" />
    <path
      class="st1"
      d="M182.4 216.6c-34.3-8.2-55.4-42.6-47.3-76.8 8.2-34.3 42.6-55.4 76.8-47.3 34.3 8.2 55.4 42.6 47.3 76.8-8.1 34.2-42.5 55.4-76.8 47.3zM361.7 259.2c-34.3-8.2-55.4-42.6-47.3-76.8 8.2-34.3 42.6-55.4 76.8-47.3 34.3 8.2 55.4 42.6 47.3 76.8-8.2 34.3-42.6 55.5-76.8 47.3zM139.7 395.8c-34.3-8.2-55.4-42.6-47.3-76.8 8.2-34.3 42.6-55.4 76.8-47.3 34.3 8.2 55.4 42.6 47.3 76.8-8.1 34.3-42.5 55.5-76.8 47.3zM319 438.5c-34.3-8.2-55.4-42.6-47.3-76.8 8.2-34.3 42.6-55.4 76.8-47.3 34.3 8.2 55.4 42.6 47.3 76.8-8.1 34.3-42.5 55.5-76.8 47.3z"
    />
  </svg>
</template>
<style>
.st0 {
  fill: #707070;
  stroke: #fff;
  stroke-width: 35;
}
.st1 {
  fill: #ffffff;
}
</style>
