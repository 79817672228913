<template>
  <div class="eventscontent">
    <div class="cardImg card">
      <!-- <img class="myImg" src="./TEDx+Speaker+Image.jpeg" /> -->
      <p id="green" class="myImg"></p>
      <div class="absolute">
        <h2>{{ videotables1.title }}</h2>
        <span>{{ videotables1.description }}</span>
      </div>
    </div>

    <div class="cardImg card">
      <!-- <img class="myImg" src="./TEDx+Speaker+Image.jpeg" /> -->
      <p id="white" class="myImg"></p>
      <div class="absolute">
        <h2>{{ videotables2.title }}</h2>
        <span>{{ videotables2.description }}</span>
      </div>
    </div>

    <div class="cardImg card">
      <!-- <img class="myImg" src="./TEDx+Speaker+Image.jpeg" /> -->
      <p class="myImg"></p>
      <div class="absolute">
        <h2>{{ videotables3.title }}</h2>
        <span>{{ videotables3.description }}</span>
      </div>
    </div>

    <div class="cardImg card">
      <!-- <img class="myImg" src="./TEDx+Speaker+Image.jpeg" /> -->
      <p id="green" class="myImg"></p>
      <div class="absolute">
        <h2>{{ videotables4.title }}</h2>
        <span>{{ videotables4.description }}</span>
      </div>
    </div>

    <div class="cardImg card">
      <!-- <img class="myImg" src="./TEDx+Speaker+Image.jpeg" /> -->
      <p id="white" class="myImg"></p>
      <div class="absolute">
        <h2>{{ videotables5.title }}</h2>
        <span>{{ videotables5.description }}</span>
      </div>
    </div>

    <div class="cardImg card">
      <!-- <img class="myImg" src="./TEDx+Speaker+Image.jpeg" /> -->
      <p class="myImg"></p>
      <div class="absolute">
        <h2>{{ videotables6.title }}</h2>
        <span>{{ videotables6.description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      videotables1: { video_file: "", title: "", description: "" },
      videotables2: { video_file: "", title: "", description: "" },
      videotables3: { video_file: "", title: "", description: "" },
      videotables4: { video_file: "", title: "", description: "" },
      videotables5: { video_file: "", title: "", description: "" },
      videotables6: { video_file: "", title: "", description: "" },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const response = await axios.get("/api");
        this.videotables1 = response.data.videotables.category1[0];
        this.videotables2 = response.data.videotables.category2[0];
        this.videotables3 = response.data.videotables.category3[0];
        this.videotables4 = response.data.videotables.category4[0];
        this.videotables5 = response.data.videotables.category5[0];
        this.videotables6 = response.data.videotables.category6[0];
      } catch (error) {
        console.error("Error loading data:", error);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/content.css"></style>
