<template>
  <p class="News">مهمان ها</p>
  <div class="line"></div>
  <swiper
    :spaceBetween="30"
    :slidesPerView="1"
    :pagination="{ clickable: true }"
    :autoplay="{ delay: 3000, disableOnInteraction: false }"
    :modules="modules"
    :breakpoints="{
      640: {
        // Mobile (up to 640px width)
        slidesPerView: 1,
      },
      768: {
        // Tablet (up to 768px width)
        slidesPerView: 2,
      },
      1024: {
        // Laptop (up to 1024px width)
        slidesPerView: 3,
      },
      1280: {
        // Desktop (above 1280px width)
        slidesPerView: 4,
      },
    }"
    class="mySwiper"
  >
    <swiper-slide v-for="guest in geustItems" :key="guest.id"
      ><div class="flex">
        <img :src="guest.image" />
        <p>{{ guest.name }}</p>
      </div>
      <p>{{ guest.detail }}</p></swiper-slide
    >
  </swiper>
</template>

<script>
// Import Swiper Vue.js components and styles
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

// Import required modules
import { Pagination, Autoplay } from "swiper/modules";
import axios from "axios";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination, Autoplay],
      geustItems: [], // Array for storing news data
    };
  },
  methods: {
    async fetchNews() {
      try {
        const response = await axios.get("/api"); // آدرس API خود را جایگزین کنید
        this.geustItems = response.data.guest; // اینجا داده‌های زیرمجموعه news را اختصاص می‌دهیم
      } catch (error) {
        console.error("خطا در دریافت داده‌های مهمان:", error);
      }
    },
  },
  mounted() {
    this.fetchNews(); // فراخوانی متد دریافت داده‌ها هنگام لود شدن
  },
};
</script>

<style scoped>
#app {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 90%;
  height: 100%;
  margin-top: 1.5rem;
}

.swiper-slide {
  font-size: 1.2rem;
  font-weight: bold;
  background: #c4c4c4;
  object-fit: cover;
  padding-top: 0.5rem;
  width: 100%;
  height: 200px;
  font-family: W_yekan;
  color: #575757;
  border-radius: 2rem;

  @media only screen and (max-width: 720px) {
    font-size: 1.1rem;
  }
}

.swiper-slide > .flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.swiper-slide > p {
  margin-top: 1.2rem;
  text-align: center;
}

.swiper-slide > img,
.swiper-slide > .flex > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.News {
  font-family: W_yekan;
  color: #707070;
  margin-top: 5%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 2% auto auto auto;
  height: 1px;
  background: #ef1746;
}
</style>
