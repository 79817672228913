<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="28"
    height="12"
    viewBox="0 0 28 12"
  >
    <defs>
      <clipPath id="clip-path">
        <rect width="28" height="12" fill="none" />
      </clipPath>
    </defs>
    <g id="Repeat_Grid_1" data-name="Repeat Grid 1" clip-path="url(#clip-path)">
      <g transform="translate(-133.5 -120.5)">
        <line
          id="Line_1"
          data-name="Line 1"
          x1="14"
          transform="translate(145.5 122.5)"
          fill="none"
          stroke="#ef1746"
          stroke-linecap="round"
          stroke-width="4"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          x1="24"
          transform="translate(135.5 130.5)"
          fill="none"
          stroke="#ef1746"
          stroke-linecap="round"
          stroke-width="4"
        />
      </g>
    </g>
  </svg>
</template>