<template>
  <div class="containerCnt">
    <div class="contentCnt">
      <div class="btns">
        <button>صفحه اصلی</button>
        <button @click="showList(0)">لیست <ArrowDown /></button>
        <ul ref="menu0" class="menus menu0">
          <li>دانش بنیان</li>
          <li>خلاق</li>
          <li>استارت اپ</li>
          <li>پارک های علم و فناوری</li>
          <li>شتاب دهنده ها</li>
          <li>فضای کار اشتراکی</li>
          <li>مراکز رشد</li>
          <li>مناطق آزاد ایران</li>
          <li>کار آفرین ها</li>
        </ul>
        <button @click="showList(1)">رویداد ها <ArrowDown /></button>
        <ul ref="menu1" class="menus menu1">
          <li @click="showEvent()">استیج</li>
          <li @click="showEvent()">سرمایه گذاری</li>
          <li @click="showEvent()">داستان من</li>
          <li @click="showEvent()">مصاحبه</li>
          <li @click="showEvent()">پنل گفت و گو</li>
          <li @click="showEvent()">کافه دورهمی</li>
        </ul>
        <button @click="showList(2)">خبرنامه <ArrowDown /></button>
        <ul ref="menu2" class="menus menu2">
          <li>خبر روز</li>
          <li>دستور عمل ها</li>
          <li>خط زمانی</li>
          <li>برنامه همایتی</li>
        </ul>
        <button @click="showList(3)">مجله نامه <ArrowDown /></button>
        <ul ref="menu3" class="menus menu3">
          <li>اینفوگرافی</li>
          <li>موشن گرافی</li>
          <li>ویدیو موشن</li>
          <li>گالری</li>
        </ul>
        <button @click="showList(4)">تماس با ما <ArrowDown /></button>
        <ul ref="menu4" class="menus menu4">
          <li @click="showContactUs()">ارتباط با ما</li>
          <li>همکاری داوطلبانه</li>
          <li>سخن ران ها</li>
          <li>حامیان مالی</li>
        </ul>
      </div>

      <div @click="hideMenu()" class="delBtn">&#x2715;</div>
    </div>
  </div>
</template>

<script>
import ArrowDown from "@/assets/svg , icon/ArrowDownRed.vue";
import HeaderCnt from "@/components/HeaderCnt.vue";
import { ref } from "vue";

export default {
  name: "HeaderMobile",

  components: {
    ArrowDown,
  },

  setup() {
    const menus = [ref(null), ref(null), ref(null), ref(null), ref(null)];
    const touchStartX = ref(0);
    const touchEndX = ref(0);
    const threshold = 100;

    function hideMenu() {
      const menu = document.getElementById("myMenuCnt");
      menu.style.right = "-100%";
    }

    function showList(index) {
      this.show = !this.show;
      if (this.show) {
        menus[index].value.style.opacity = "1";
        menus[index].value.style.display = "block";
      } else {
        menus[index].value.style.opacity = "0";
        menus[index].value.style.display = "none";
      }
    }

    function showEvent() {
      const target = document.getElementById("eventsContent");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }

    function showContactUs() {
      const target = document.getElementById("showContactUs");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }

    function handleTouchStart(e) {
      touchStartX.value = e.changedTouches[0].screenX;
    }

    function handleTouchEnd(e) {
      touchEndX.value = e.changedTouches[0].screenX;
      if (touchEndX.value > touchStartX.value + threshold) {
        showList();
      } else if (touchEndX.value < touchStartX.value - threshold) {
        hideMenu();
      }
    }

    return {
      menu0: menus[0],
      menu1: menus[1],
      menu2: menus[2],
      menu3: menus[3],
      menu4: menus[4],
      show: false,
      showList,
      HeaderCnt,
      showEvent,
      showContactUs,
      touchStartX,
      touchEndX,
      hideMenu,
      handleTouchStart,
      handleTouchEnd,
      menus,
    };
  },
};
</script>

<style scoped src="@/assets/css/headerMoblie.css"></style>
